<template>
    <div>
        <p style="width: 100%;color: red;font-weight: bold;margin: 5px 0;text-align: left">* 1.4(포함)배당 이상경기만 폴더수로 인정됩니다</p>
        <div class="sports_bonus">

            <button class="bw3" :class="{'active':  selectedGameId=== game1.id}"
                    @click="clickBonus(game1)"><i class="fa fa-star" style="color: gainsboro"> 1.03 (3폴더이상)</i>
            </button>
            <button class="bw3" :class="{'active':  selectedGameId=== game2.id}"
                    @click="clickBonus(game2)"><i class="fa fa-star" style="color: skyblue"></i>   1.05 (5폴더이상)
            </button>
            <button class="bw3" :class="{'active':  selectedGameId=== game3.id}"
                    @click="clickBonus(game3)"><i class="fa fa-star" style="color: gold"></i>   1.07 (7폴더이상)
            </button>
        </div>
    </div>

</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {
        RECEIVE_DISPLAY_POSTION,
        RECEIVE_SPORTS_CART_ITEM,
        RECEIVE_SPORTS_CART_ITEM_DEL
    } from "../../store/mutation-types";
    import {mapGetters} from "vuex";

    export default {
        name: "SportsBonusComp",
        data() {
            return {
                sportsConst,
                selectedGameId: 0,
                game1: {
                    "id": 1111111111,
                    "leagueId": 0,
                    "homeTeamName": "1.03 [3폴더이상 보너스]",
                    "awayTeamName": "",
                    "homeTeamOdds": 1.03,
                    "drawOdds": "0",
                    "awayTeamOdds": "0",
                    "groupCode": "0",
                    "isShow": true,
                    "selectWay": 1,
                    "selectOdd": 1.03,
                    "kindText": "승무패",
                    "showLeague": false,
                    "isbonus": sportsConst.YES,
                    "kind": sportsConst.GAME_KIND_1X2,
                    "bonustype": 3,
                },
                game2: {
                    "id": 5555555555,
                    "leagueId": 0,
                    "homeTeamName": "1.05 [5폴더이상 보너스]",
                    "awayTeamName": "",
                    "homeTeamOdds": 1.05,
                    "drawOdds": "0",
                    "awayTeamOdds": "0",
                    "groupCode": "0",
                    "isShow": true,
                    "selectWay": 1,
                    "selectOdd": 1.05,
                    "kindText": "승무패",
                    "showLeague": false,
                    "isbonus": sportsConst.YES,
                    "kind": sportsConst.GAME_KIND_1X2,
                    "bonustype": 5,
                },
                game3: {
                    "id": 7777777777,
                    "leagueId": 0,
                    "homeTeamName": "1.07 [7폴더이상 보너스]",
                    "awayTeamName": "",
                    "homeTeamOdds": 1.07,
                    "drawOdds": "0",
                    "awayTeamOdds": "0",
                    "groupCode": "0",
                    "isShow": true,
                    "selectWay": sportsConst.YES,
                    "selectOdd": 1.07,
                    "kindText": "승무패",
                    "showLeague": false,
                    "isbonus": 1,
                    "kind": sportsConst.GAME_KIND_1X2,
                    "bonustype": 7,
                },
            }
        },
        computed: {
            ...mapGetters({
                'cartItems': 'getSportsCartItems',
            }),
        },
        methods: {
            clickBonus(game) {
                this.selectedGameId = 0;
                let allowBedangBetCartCount = 0;
                this.cartItems.forEach((item) => {
                    if (item.selectOdd >= 1.5) {
                        allowBedangBetCartCount += 1
                    }
                    if (item.isbonus === sportsConst.YES) {
                        this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
                    }
                })
                this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
                this.selectedGameId = game.id;
                // console.log(this.cartItems)
                // if (allowBedangBetCartCount < 3) {
                //     return false;
                // }
                // if (allowBedangBetCartCount >= 7) {
                //     this.selectedGameId = game.id;
                //     this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
                // } else if (allowBedangBetCartCount < 7 && allowBedangBetCartCount >= 5) {
                //     if (game.bonustype === 5 || game.bonustype === 3) {
                //         this.selectedGameId = game.id;
                //         this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
                //     }
                // } else if (allowBedangBetCartCount < 5 && allowBedangBetCartCount >= 3) {
                //     if (game.bonustype === 3) {
                //         this.selectedGameId = game.id;
                //         this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
                //     }
                // }

            }
        },
    }
</script>

<style scoped>
    .sports_bonus {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .sports_bonus button {
        width: 33.1%;
        height: 32px;
        background: linear-gradient(180deg, var(--sprotsGameBg1), var(--sprotsGameBg2));
        border-radius: 1px;
        color: #fff9ff;
        border: 1px solid #4c473f;
    }

    .active {
        background: linear-gradient(180deg, var(--sprotsGameBgActicve1), var(--sprotsGameBgActicve2)) !important;
    }
</style>