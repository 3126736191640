<template>

  <div>
    <sports-links-comp :display-position="displayPosition"></sports-links-comp>
    <sports-game-type-scroll-comp></sports-game-type-scroll-comp>
    <sports-bonus-comp v-if="displayPosition == sportsConst.GAME_DISPLAYPOSITION_1x2 || displayPosition == sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER"></sports-bonus-comp>
    <transition-group class="sports" tag="div">
      <div class="match_area" v-for="(game,index) in games" :key="index" v-if="game.isShow">
        <div class="league" v-if="game.showLeague">
                    <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER"><img
                        src="../../assets/images/icon/type/soccer.svg"
                        style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BASEBALL"><img
              src="../../assets/images/icon/type/baseball.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BASKETBALL"><img
              src="../../assets/images/icon/type/basketball.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_VOLLEYBALL"><img
              src="../../assets/images/icon/type/volleyball.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_HOCKEY"><img
              src="../../assets/images/icon/type/hockey.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_ESPORTS"><img
              src="../../assets/images/icon/type/esports.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_TENNIS"><img
              src="../../assets/images/icon/type/tennis.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_USA_FOOTBALL"><img
              src="../../assets/images/icon/type/football.svg"
              style="width: 20px" alt=""></span>
          <span v-if="game.league.gameTypeId === sportsConst.GAME_TYPE_BOXING"><img
              src="../../assets/images/icon/type/boxing.svg"
              style="width: 20px" alt=""></span>
          <img alt="" :src="game.league.imageUrl" class="league_img">
          <span class="league_name">{{ game.league.leagueName }}</span>
          <span class="st" style="float: right">{{ game.startTime|datef('MM/DD HH:mm') }}</span>
        </div>
        <div class="match clearfix">
          <div class="start_time">
            {{ game.startTime|datef('MM월DD일 HH:mm') }}
          </div>
          <div class="team_info">
            <div class="detail">
              <div class="home bg_1x2"
                   :class="{'active_sports':game.selectWay === sportsConst.WAY_HOME}"
                   @click="selectItem(game,sportsConst.WAY_HOME)">
                <span class="home_name" v-html="game.homeTeamName"></span>
                <span class="home_odds">
                                 <i v-if="game.kind === sportsConst.GAME_KIND_HANDICAP"
                                    class="fa fa-h-square text-green"></i>
                                 <i v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                    class="fa fa-arrow-up text-red"></i>
                                     {{ game.homeTeamOdds }}
                                </span>
              </div>
              <!--무 3way-->
              <div class="draw bg_1x2" v-if="game.kind === sportsConst.GAME_KIND_1X2 && game.drawOdds > 0"
                   :class="{'active_sports':game.selectWay === sportsConst.WAY_DRAW}"
                   @click="selectItem(game,sportsConst.WAY_DRAW)">
                <span class="draw_bedang">{{ game.drawOdds }}</span>
              </div>
              <div class="draw ho" v-if="game.kind === sportsConst.GAME_KIND_1X2 && game.drawOdds <= 0">
                <span>vs</span>
              </div>
              <!--핸디캡 오버언더-->
              <div v-if="game.kind === sportsConst.GAME_KIND_HANDICAP" class="draw ho" style="color: #3cfc52">
                {{ game.handicapVal }}
              </div>
              <div v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER" class="draw ho">
                {{ game.overunderVal }}
              </div>

              <div class="away bg_1x2" :class="{'active_sports':game.selectWay === sportsConst.WAY_AWAY}"
                   @click="selectItem(game,sportsConst.WAY_AWAY)">
                                <span class="away_name" v-html="game.awayTeamName">
                                </span>
                <span class="away_odds">
                                    {{ game.awayTeamOdds }}
                                    <i v-if="game.kind === sportsConst.GAME_KIND_HANDICAP"
                                       class="fa fa-h-square text-green"></i>
                                    <i v-if="game.kind === sportsConst.GAME_KIND_OVERUNDER"
                                       class="fa fa-arrow-down text-blue"></i>
                                </span>
              </div>
            </div>
          </div>
          <div class="more">
                        <span
                            v-if="game.kind === sportsConst.GAME_KIND_1X2 && game.displayPosition === sportsConst.GAME_DISPLAYPOSITION_1x2&& game.gc > 0 && game.isbaseball1nscore == sportsConst.NOT"
                            @click="moreGames(game)">
                            <i class="fa fa-refresh"
                               style="cursor: pointer;box-sizing: border-box;padding: 2px"></i> {{ game.gc }}
                        </span>
          </div>
        </div>
        <transition-group tag="div" class="sports" name="fade">
          <div class="match clearfix" v-show="game.showMoreGames" v-for="(mg,idx) in game.moreGames" :key="'s'+idx">
            <div class="start_time">
              {{ mg.startTime|datef('MM월DD일 HH:mm') }}
            </div>
            <div class="team_info">
              <div class="detail">
                <div class="home bg_ho"
                     :class="{'active_sports':mg.selectWay === sportsConst.WAY_HOME,'active_mingapgame':mg.mingapgame ===1}"
                     @click="selectItem(mg,sportsConst.WAY_HOME)">
                  <span class="home_name" v-html="mg.homeTeamName"></span>
                  <span class="home_odds">
                                 <i v-if="mg.kind === sportsConst.GAME_KIND_HANDICAP"
                                    class="fa fa-h-square text-green"></i>
                                 <i v-if="mg.kind === sportsConst.GAME_KIND_OVERUNDER"
                                    class="fa fa-arrow-up text-red"></i>
                                     {{ mg.homeTeamOdds }}
                                </span>
                </div>
                <!--핸디캡 오버언더-->
                <div v-if="mg.kind === sportsConst.GAME_KIND_HANDICAP" class="draw ho" style="color: #3cfc52">
                  {{ mg.handicapVal }}
                </div>
                <div v-if="mg.kind === sportsConst.GAME_KIND_OVERUNDER" class="draw ho">{{ mg.overunderVal }}
                </div>
                <div class="away bg_ho"
                     :class="{'active_sports':mg.selectWay === sportsConst.WAY_AWAY,'active_mingapgame':mg.mingapgame ===1}"
                     @click="selectItem(mg,sportsConst.WAY_AWAY)">
                                <span class="away_name" v-html="mg.awayTeamName">
                                </span>
                  <span class="away_odds">
                                    {{ mg.awayTeamOdds }}
                                    <i v-if="mg.kind === sportsConst.GAME_KIND_HANDICAP"
                                       class="fa fa-h-square text-green"></i>
                                    <i v-if="mg.kind === sportsConst.GAME_KIND_OVERUNDER"
                                       class="fa fa-arrow-down text-blue"></i>
                                </span>
                </div>
              </div>
            </div>
            <div class="more">
              &nbsp
            </div>
          </div>
        </transition-group>

      </div>
    </transition-group>
  </div>


</template>

<script>
import sportsConst from '../../common/sportsConst';
import GameTypeButtonsComp from "./GameTypeButtonsComp";
import {
  RECEIVE_COMMON_CONFIG,
  RECEIVE_SPORTS_CART_ITEM,
  RECEIVE_SPORTS_CART_ITEM_DEL
} from "../../store/mutation-types";
import {mapGetters} from 'vuex'
import SportsLinksComp from "./SportsLinksComp";
import SubTitle from "../SubTitle";
import {checkLoginMinxin, leisureGameMixin} from "../../common/mixin";
import SportsBonusComp from "./SportsBonusComp";
import SportsGameTypeScrollComp from "../SportsGameTypeScrollComp";

export default {
  name: "SportsNormalComp",
  mixins: [checkLoginMinxin],
  components: {SportsGameTypeScrollComp, SportsBonusComp, SubTitle, SportsLinksComp, GameTypeButtonsComp},
  props: {
    displayPosition: {
      type: Number,
      default() {
        return sportsConst.GAME_DISPLAYPOSITION_1x2;
      }
    },
    gameList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      selectLeagueList: [],
      selectGameType: 0,
      sportsConst,
      mingapbetuse: true,
    }
  },
  computed: {
    ...mapGetters({
      'cartItems': 'getSportsCartItems',
      'rankConfig': 'getRankConfig',
      'bonusConfig': 'getBonusConfig',
      'crossRuleConfig': 'getCrossRuleConfig',/*
        'getSelectedGameType': 'getSelectedGameType',
        'getSelectedLeagueList': 'getSelectedLeagueList',*/
    }),
    games() {
      let oldGameTemp = null
      this.gameList.forEach((g) => {

        //승무패,핸디오언 텍스트 설정
        switch (g.kind) {
          case sportsConst.GAME_KIND_1X2:
            g.kindText = '승무패'
            break;
          case sportsConst.GAME_KIND_HANDICAP:
            g.kindText = '핸디캡'
            break;
          case sportsConst.GAME_KIND_OVERUNDER:
            g.kindText = '오버언더'
            break;
        }
        //리그바 숨김여부
        if (g.isShow) {
          g.showLeague = oldGameTemp == null || oldGameTemp.leagueId !== g.leagueId || oldGameTemp.startTime !== g.startTime;
          oldGameTemp = g;
        }
      })
      return this.gameList
    },
  },
  methods: {
    /*경기선택*/
    selectItem(game, way) {


      //최대 베팅가능 폴더수 체크
      if (this.cartItems.indexOf(game) === -1) {
        if (this.cartItems.length + 1 > this.rankConfig.betFolderMaxLimit) {
          this.$swal({
            title: '최대 ' + this.rankConfig.betFolderMaxLimit + '폴더 배팅가능합니다',
            type: 'error',
            showCancelButton: false,
            showConfirmButton: true
          })
          return false;
        }
      }


      //크로스룰
      let crossable = true;
      this.cartItems.forEach((item, index) => {
        if (item.isbonus === sportsConst.NOT) {
          let res = this.checkCrossRule(item, game, way)
          if (!res.success) {
            this.$swal({
              title: res.message,
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
            crossable = false;
          }
        }
      })
      if (!crossable) {
        return false
      }

      //저장된게임판단
      if (this.cartItems.indexOf(game) !== -1) {
        if (game.selectWay === way) {
          this.setGame(game, sportsConst.WAY_NONE)
          this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, game)
        } else {
          game.selectWay = way
          this.setGame(game, way)
        }
      } else {
        this.cartItems.forEach((item) => {
          if (item.id !== game.id && item.kind !== sportsConst.GAME_KIND_1X2 && item.kind === game.kind && item.groupCode === game.groupCode) {
            this.$store.commit(RECEIVE_SPORTS_CART_ITEM_DEL, item)
          }
        })
        this.$store.commit(RECEIVE_SPORTS_CART_ITEM, game)
        this.setGame(game, way)
      }
    },
    setGame(game, way) {//선택팀 및 선택된 배당 설정
      this.$set(game, 'selectWay', way)
      if (way === sportsConst.WAY_NONE) {
        this.$set(game, 'selectOdd', 1)
      }
      if (way === sportsConst.WAY_HOME) {
        this.$set(game, 'selectOdd', game.homeTeamOdds)
      }
      if (way === sportsConst.WAY_DRAW) {
        this.$set(game, 'selectOdd', game.drawOdds)
      }
      if (way === sportsConst.WAY_AWAY) {
        this.$set(game, 'selectOdd', game.awayTeamOdds)
      }
    },

    checkCrossRule(game, cg, compareGameSelectedVal) {
      if (game.id === cg.id && game.selectWay === compareGameSelectedVal) {
        return {'success': true, 'message': ''}
      }

      //코로스 베팅페이지에서만 적용
      /*if (this.displayPosition !== sportsConst.GAME_DISPLAYPOSITION_1x2) {
        return {'success': true, 'message': ''}
      }*/
      let compareGame = {};
      compareGame.selectWay = compareGameSelectedVal;
      compareGame.kind = cg.kind;
      compareGame.league = cg.league;
      compareGame.groupCode = cg.groupCode;
      compareGame.mingapgame = cg.mingapgame;
      compareGame.isbaseball1nscore = cg.isbaseball1nscore;
      let gameTypeId = game.league.gameTypeId;
      let ruletype = null;
      let ruletypeText = ''

      if (game.displayPosition === sportsConst.GAME_DISPLAYPOSITION_1x2
          || game.displayPosition === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER) {
        ruletype = sportsConst.CROSS_RULE_TYPE_FULLTIME
      } else {
        ruletype = sportsConst.CROSS_RULE_TYPE_HANLFTIME
        ruletypeText = '스페셜,실시간 '
      }

      //경기종목이 다르면 패스
      if (game.league.gameTypeId !== compareGame.league.gameTypeId) {
        return {'success': true, 'message': ''}
      }
      //승무패 경기가 없으면 패스
      /*if (game.kind !== sportsConst.GAME_KIND_1X2 && compareGame.kind !== sportsConst.GAME_KIND_1X2) {
        return {'success': true, 'message': ''}
      }*/
      //승무패+승무패이면 패스
      if (game.kind === (sportsConst.GAME_KIND_1X2) && compareGame.kind === sportsConst.GAME_KIND_1X2) {
        return {'success': true, 'message': ''}
      }
      //동일그룹경기가 아니면 패스
      if (game.groupCode !== compareGame.groupCode) {
        return {'success': true, 'message': ''}
      }
      let protoGame = null;
      let handicapGame = null;
      let overUnderGame = null;

      if (game.kind === (sportsConst.GAME_KIND_1X2)) {
        protoGame = game;
      } else if (game.kind === (sportsConst.GAME_KIND_HANDICAP)) {
        handicapGame = game;
      } else if (game.kind === (sportsConst.GAME_KIND_OVERUNDER)) {
        overUnderGame = game;
      }
      if (compareGame.kind === (sportsConst.GAME_KIND_1X2)) {
        protoGame = compareGame;
      } else if (compareGame.kind === (sportsConst.GAME_KIND_HANDICAP)) {
        handicapGame = compareGame;
      } else if (compareGame.kind === (sportsConst.GAME_KIND_OVERUNDER)) {
        overUnderGame = compareGame;
      }
      if (null != protoGame && protoGame.isbaseball1nscore != null && protoGame.isbaseball1nscore == sportsConst.YES) {
        if (null != handicapGame || null != overUnderGame) {
          return {'success': false, 'message': '야구 동일경기 1이닝득/무득 경기는 핸디/오언 경기랑 조합할수 없습니다'}
        }
      }


      let rule = null;
      for (let i = 0; i < this.crossRuleConfig.length; i++) {
        if (this.crossRuleConfig[i].gameTypeId === gameTypeId && this.crossRuleConfig[i].ruletype === ruletype) {
          rule = this.crossRuleConfig[i];
        }
      }
      if (null == rule) {
        return {'success': true, 'message': ''}
      }
      if (rule.crossable === (sportsConst.DISABLE)) {
        return {'success': false, 'message': '크로스 베팅이 허용되지 않습니다'}
      }
      if (rule.sgcrossable === (sportsConst.DISABLE)) {
        return {'success': false, 'message': rule.typeName + ' 동일경기 크로스 베팅이 허용되지 않습니다'}
      }

      //승패 핸디캡
      if (null != protoGame && null != handicapGame) {
        //승무패 홈승 + 핸디캡 홈승
        if (protoGame.selectWay === (sportsConst.WAY_HOME) && handicapGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.protoHomeHandicapHome;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 핸디캡 홈승은 조합할수 없습니다'}
          }
        }
        //승무패 홈승 + 핸디캡 원정승
        if (protoGame.selectWay === (sportsConst.WAY_HOME) && handicapGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.protoHomeHandicapAway;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 핸디캡 원정승은 조합할수 없습니다'}
          }
        }
        //승무패 무 + 핸디캡 홈승
        if (protoGame.selectWay === (sportsConst.WAY_DRAW) && handicapGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.protoDrawHandicapHome;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 핸디캡 홈승은 조합할수 없습니다'}
          }
        }
        //승무패 무 + 핸디캡 원정승
        if (protoGame.selectWay === (sportsConst.WAY_DRAW) && handicapGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.protoDrawHandicapAway;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 핸디캡 원정승은 조합할수 없습니다'}
          }
        }
        //승무패 원정승 + 핸디캡 홈승
        if (protoGame.selectWay === (sportsConst.WAY_AWAY) && handicapGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.protoAwayHandicapHome;
          if (ruleStatus === (sportsConst.DISABLE)) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 핸디캡 홈승은 조합할수 없습니다'}
          }
        }
        //승무패 원정승 + 핸디캡 원정승
        if (protoGame.selectWay === (sportsConst.WAY_AWAY) && handicapGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.protoAwayHandicapAway;
          if (ruleStatus === (sportsConst.DISABLE)) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 핸디캡 원정승은 조합할수 없습니다'}
          }
        }

        //동일경기 최소차이경기가 아닐경우 베팅실패
        if (this.mingapbetuse && !this.checkMinGapRule(handicapGame, null)) {
          return {'success': false, 'message': '축구 크로스 경기는 최소배당차이 핸디/오언 경기를 선택하여 베팅가능합니다'}
        }
      }

      //승패 오버언더
      if (null != protoGame && null != overUnderGame) {
        //승무패 홈승 + 오버
        if (protoGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.protoHomeOverunderOver;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 오버는 조합할수 없습니다'}
          }
        }
        //승무패 홈승 + 언더
        if (protoGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.protoHomeOverunderUnder;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 홈승, 언더는 조합할수 없습니다'}
          }
        }
        //승무패 무 + 오버
        if (protoGame.selectWay === (sportsConst.WAY_DRAW) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.protoDrawOverunderOver;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 오버는 조합할수 없습니다'}
          }
        }
        //승무패 무 + 언더
        if (protoGame.selectWay === (sportsConst.WAY_DRAW) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.protoDrawOverunderUnder;
          if (ruleStatus === sportsConst.DISABLE) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 무, 언더는 조합할수 없습니다'}
          }
        }
        //승무패 원정승 + 오버
        if (protoGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.protoAwayOverunderOver;
          if (ruleStatus === (sportsConst.DISABLE)) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 오버는 조합할수 없습니다'}
          }
        }
        //승무패 원정승 + 언더
        if (protoGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.protoAwayOverunderUnder;
          if (ruleStatus === (sportsConst.DISABLE)) {
            return {'success': false, 'message': rule.typeName + ' 동일경기 승무패 원정승, 언더는 조합할수 없습니다'}
          }
        }
        if (this.mingapbetuse && !this.checkMinGapRule(overUnderGame, null)) {
          return {'success': false, 'message': '축구 크로스 경기는 최소배당차이 핸디/오언 경기를 선택하여 베팅가능합니다'}
        }
      }

      //핸디캡 오버언더
      if (null != handicapGame && null != overUnderGame) {

        //핸디캡 홈승 + 오버
        if (handicapGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.handicapHomeOverunderOver;
          if (ruleStatus === (sportsConst.DISABLE)) {
            return {
              'success': false,
              'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 홈승, 오버는 조합할수 없습니다'
            }
          }
        }

        //핸디 홈승  + 언더
        if (handicapGame.selectWay === (sportsConst.WAY_HOME) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.handicapHomeOverunderUnder;
          if (ruleStatus === sportsConst.DISABLE) {
            return {
              'success': false,
              'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 홈승, 언더는 조합할수 없습니다'
            }
          }
        }

        //핸디캡 원정승 + 오버
        if (handicapGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_HOME)) {
          let ruleStatus = rule.handicapAwayOverunderOver;
          if (ruleStatus === sportsConst.DISABLE) {
            return {
              'success': false,
              'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 원정승, 오버는 조합할수 없습니다'
            }
          }
        }
        //핸디캡 원정승 + 언더
        if (handicapGame.selectWay === (sportsConst.WAY_AWAY) && overUnderGame.selectWay === (sportsConst.WAY_AWAY)) {
          let ruleStatus = rule.handicapAwayOverunderUnder;
          if (ruleStatus === sportsConst.DISABLE) {
            return {
              'success': false,
              'message': ruletypeText + rule.typeName + ' 동일경기 핸디캡 원정승, 언더는 조합할수 없습니다'
            }
          }
        }

        if (this.mingapbetuse && !this.checkMinGapRule(handicapGame, overUnderGame)) {
          return {'success': false, 'message': '축구 크로스 경기 경기는 최소배당차이 핸디/오언 경기를 선택하여 베팅가능합니다'}
        }
      }


      return {'success': true, 'message': ''}
    },

    checkMinGapRule(game1, game2) {
      if (null != game1 && !(game1.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER)) {
        return true
      }
      if (null != game2 && !(game2.league.gameTypeId === sportsConst.GAME_TYPE_SOCCER)) {
        return true
      }
      let flag = false;
      if (game1 != null && game1.mingapgame === 1) {
        flag = true;
      }
      if (game2 != null && game2.mingapgame === 1) {
        flag = true;
      }
      return flag;
    },

    moreGames(game) {
      this.$emit('moreGames', game)
    }
  },
  created() {
  },
  watch: {
    "$store.state.selectedGameType": {
      deep: true,
      handler: function (newValue) {
      }
    }

  }
}
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0
}
</style>
