<template>
    <div>
        <div class="move-tabs">
            <div class="tabs-wrapper" ref='tabsWrapper'>
                <div ref='tab' class="tab">
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_ALL)" :class="{'active':selected == sportsConst.GAME_TYPE_ALL}">
                        <div class='expand-block'>
                            <span>전체 </span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/type/all.svg" alt="">-->
                                ({{alltypecount}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_SOCCER)" :class="{'active':selected == sportsConst.GAME_TYPE_SOCCER}">
                        <div class='expand-block'>
                            <span>축구 </span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/football-ico.png" alt="">-->
                                ({{soccer}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_BASKETBALL)" :class="{'active':selected == sportsConst.GAME_TYPE_BASKETBALL}">
                        <div class='expand-block'>
                            <span>농구 </span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/basketball-ico.png" alt="">-->
                                ({{basketball}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_BASEBALL)" :class="{'active':selected == sportsConst.GAME_TYPE_BASEBALL}">
                        <div class='expand-block'>
                            <span>야구 </span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/baseball-ico.png" alt="">-->
                                ({{baseball}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_VOLLEYBALL)" :class="{'active':selected == sportsConst.GAME_TYPE_VOLLEYBALL}">
                        <div class='expand-block'>
                            <span>배구</span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/volleyball-ico.png" alt="">-->
                                 ({{volleyball}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_HOCKEY)" :class="{'active':selected == sportsConst.GAME_TYPE_HOCKEY}">
                        <div class='expand-block'>
                            <span>하키</span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/hockey-ico.png" alt="">-->
                                ({{hockey}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_USA_FOOTBALL)" :class="{'active':selected == sportsConst.GAME_TYPE_USA_FOOTBALL}">
                        <div class='expand-block'>
                            <span>미식축구</span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/usa-football-ico.png" alt="">-->
                                 ({{football}})
                                </span>
                        </div>
                    </div>
                    <div class='tab-item' @click="selectedType(sportsConst.GAME_TYPE_ESPORTS)" :class="{'active':selected == sportsConst.GAME_TYPE_ESPORTS}">
                        <div class='expand-block'>
                            <span>esports</span>
                            <span class="ebimg">
<!--                                    <img src="../assets/images/icon/esport-ico.png" alt="">-->
                                 ({{esports}})
                                </span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>


</template>

<script>
    import sportsConst from "../common/sportsConst";
    import BScroll from 'better-scroll'
    import {getSportsGameCountGroupByType} from "../network/sportsRequest";

    export default {
        name: "SportsGameTypeScrollComp",
        data() {
            return {
                sportsConst,
                tabs:['전체','축구','농구','야구','배구','하키','eports','미식축구'],
                mX: 0,
                tabWidth: 12.5,
                selected:sportsConst.GAME_TYPE_ALL,
                soccer: 0,
                baseball: 0,
                basketball: 0,
                volleyball: 0,
                football: 0,
                hockey: 0,
                esports: 0,
                tennis: 0,
                boxing: 0,
                pba: 0,
                alltypecount: 0,
            }
        },
        methods: {
            selectedType(type) {
                this.selected = type
                this.$store.state.selectedGameType = type
            },
            _initMenu() {
                // const tabsWidth = this.tabWidth
                // const width = 8 * tabsWidth
                // this.$refs.tab.style.width = `${width}%`
                this.$nextTick(() => {
                    if (!this.scroll) {
                        this.scroll = new BScroll(this.$refs.tabsWrapper, {
                            scrollX: true,
                            eventPassthrough: 'vertical',
                        })
                    } else {
                        this.scroll.refresh()
                    }
                })
            },
        },
        mounted() {
            this.$nextTick(() => {
                this._initMenu()

                getSportsGameCountGroupByType(sportsConst.INPLAY_NOT).then(res => {
                    if (res.data.success) {
                        let map = res.data.data;
                        this.soccer = map[sportsConst.GAME_TYPE_SOCCER] !== undefined ? map[sportsConst.GAME_TYPE_SOCCER] : 0
                        this.baseball = map[sportsConst.GAME_TYPE_BASEBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASEBALL] : 0
                        this.basketball = map[sportsConst.GAME_TYPE_BASKETBALL] !== undefined ? map[sportsConst.GAME_TYPE_BASKETBALL] : 0
                        this.volleyball = map[sportsConst.GAME_TYPE_VOLLEYBALL] !== undefined ? map[sportsConst.GAME_TYPE_VOLLEYBALL] : 0
                        this.hockey = map[sportsConst.GAME_TYPE_HOCKEY] !== undefined ? map[sportsConst.GAME_TYPE_HOCKEY] : 0
                        this.football = map[sportsConst.GAME_TYPE_USA_FOOTBALL] !== undefined ? map[sportsConst.GAME_TYPE_USA_FOOTBALL] : 0
                        this.esports = map[sportsConst.GAME_TYPE_ESPORTS] !== undefined ? map[sportsConst.GAME_TYPE_ESPORTS] : 0
                        this.tennis = map[sportsConst.GAME_TYPE_TENNIS] !== undefined ? map[sportsConst.GAME_TYPE_TENNIS] : 0
                        this.pba = map[sportsConst.GAME_TYPE_PBA] !== undefined ? map[sportsConst.GAME_TYPE_PBA] : 0
                        this.alltypecount = this.soccer + this.baseball + this.basketball + this.volleyball + this.hockey
                            + this.football + this.esports + this.pba;
                    }
                })

            })
        },
    }
</script>

<style scoped>
    .move-tabs {
        position: relative;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .tabs-wrapper{
        height: 60px;
        width: 100%;
        box-sizing: border-box;
        overflow: hidden;
        white-space: nowrap;
    }
    .tab{
        width: 100%;
    }
    .tab-item {
        float: left;
        width: 12.4%;
        margin-right: 1px;
        background: linear-gradient(180deg, #5c5c5c, #5c5c5c);
        border-radius: 1px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .expand-block {
        font-size: 12px;
        color: #fff9ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
    }
    .ebimg{
        margin-top: 5px;
    }
    .ebimg img{
        height: 20px;
    }
    .active{
        color: #ffa500;
        font-weight: bold;
        --font-style: italic;
        background: linear-gradient(180deg, #3f3d3e, #535253);
    }
    @media screen and (max-width: 1024px) {
        .tab{
            width: 720px;
        }
        .tab-item {
            width: 12% !important;
        }
    }
</style>