<template>

  <div class="sports_links">
    <button class="bw3" v-if="postion === 1" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_1x2}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_1x2)">크로스</button>
    <button class="bw3" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_HANDICAPOVERUNDER)">핸디캡</button>
    <button class="bw3" v-if="postion === 1" :class="{'sports_link_active': disp === sportsConst.GAME_DISPLAYPOSITION_SPECIAL}"
            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_SPECIAL)">스페셜</button>
<!--    <button class="btn02 bw3" :class="{'gt_type_active': disp === sportsConst.GAME_DISPLAYPOSITION_LIVE}"-->
<!--            @click="changeSportsDisplay(sportsConst.GAME_DISPLAYPOSITION_LIVE)"><i class="fa fa-television"></i> Live</button>-->
  </div>
</template>

<script>
  import sportsConst from "../../common/sportsConst";
  import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

  export default {
    name: "SportsLinksComp",
    props: {
      displayPosition: {
        type: Number,
        default() {
          return sportsConst.GAME_DISPLAYPOSITION_1x2;
        }
      },
      postion:{
        type: Number,
        default() {
          return 1;
        }
      }
    },
    data(){
      return {
        sportsConst,
        disp: this.displayPosition ? this.displayPosition : sportsConst.GAME_DISPLAYPOSITION_1x2,
      }
    },
    methods:{
      changeSportsDisplay(disp) {
        //SportsOriginal.vue -> $on
        this.disp = disp
        this.$store.commit(RECEIVE_DISPLAY_POSTION, 0);
        this.$store.commit(RECEIVE_DISPLAY_POSTION, disp);
      }
    },
  }
</script>

<style scoped>

</style>